<template>
  <div class="container">
    <div class="content">
      <component :is="'script'" charset="utf-8" src="https://widget.bandsintown.com/main.min.js">
        // JS Here
      </component>
      <a class="bit-widget-initializer" data-artist-name="Principe Valiente" data-display-local-dates="false"
        data-display-past-dates="true" data-auto-style="false" data-text-color="#000000" data-link-color="#b13e2e"
        data-background-color="rgba(0,0,0,0)" data-display-limit="15" data-display-start-time="false"
        data-link-text-color="#ffffff" data-display-lineup="true" data-display-play-my-city="true"
        data-separator-color="rgba(255, 255, 255, 0.5)"></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowsPage",
};
</script>

<style>
.bit-widget,
.bit-widget.bit-event,
.bit-widget a,
.bit-widget .bit-past-events-show-all-button {
  font-family: var(--regular) !important;
  color: var(--white) !important;
}
.bit-widget .bit-clickable,
.bit-widget .bit-venue {
  color: var(--highlight) !important;
}
.bit-widget .bit-offers,
.bit-widget .bit-play-my-city-button,
.bit-widget .bit-past-events-show-all-button {
  background-color: var(--highlight) !important;
}
.bit-widget .bit-rsvp,
.bit-widget .bit-offers,
.bit-widget .bit-play-my-city-button,
.bit-widget .bit-past-events-show-all-button {
  border: 1px solid var(--highlight) !important;
}

</style>