import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/components/HomePage.vue";
import Bio from "@/components/Bio.vue";
import Shows from "@/components/Shows.vue";
import Shop from "@/components/Shop.vue";
import Contact from "@/components/Contact.vue";
import Press from "@/components/Press.vue";

const routes = [
  {
    path: "/",
    name: "homepage",
    component: HomePage,
  },
  {
    path: "/bio",
    name: "bio",
    component: Bio,
  },
  {
    path: "/shows",
    name: "shows",
    component: Shows,
  },
  {
    path: "/shop",
    name: "shop",
    component: Shop,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/press",
    name: "press",
    component: Press,
  },
  {
    path: "/:catchAll(.*)",
    component: HomePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
