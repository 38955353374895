<template>
  <div class="center">
    <div v-if="!show" class="input">
      <div>
        <label :class="{ error }" class="label">Password</label>
      </div>
      <input v-model="password" type="password" @change="submit" />
    </div>
    <div v-else>
      <ul>
        <li><small>Tracks (.wav/.mp3):</small></li>
        <li>Barricades (from the new album in 2022)</li>
        <li>Porcelain (from the new album in 2022)</li>
        <li>I Am You (from the new album in 2022)</li>
        <li>Never Change (from the new album in 2022)</li>
        <li>She Never Returned (Mixed by Ed Buller, 2014)</li>
        <li>Strangers In The Night (2017)</li>
        <li>Take Me With You (Mixed by Ed Buller, 2014)</li>
        <li>The Fighting (2014)</li>
        <li><small>Documents (.docx)</small></li>
        <li>Music videos list</li>
        <li>Press release</li>
        <li><small>Images (.jpg)</small></li>
        <li>"Barricades" cover artwork (2022 album)</li>
        <li>Press image</li>
      </ul>
      <hr />
      <a href="https://www.dropbox.com/sh/tnxehtsjfc0zgry/AACNgNv0598aH6Cl-wXpdcjra?dl=0" target="_blank">Download
        presskit</a>
    </div>
    <div class="bg-img"></div>
  </div>
</template>

<script>
export default {
  name: "PressPage",
  data() {
    return {
      password: "",
      show: false,
      error: false,
    };
  },
  methods: {
    submit() {
      if (this.password !== "promo2024") {
        this.error = true;
      } else {
        this.error = false;
        this.show = true;
      }
    },
  },
};
</script>

<style scoped>
.input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
}

small {
  display: block;
  margin-top: var(--margin-small);
  color: var(--highlight);
}

input {
  width: 220px;
  line-height: 30px;
  border: 1px solid var(--black);
  border-radius: 5px;
  outline: none;
}

a {
  display: block;
  font-weight: bold;
  margin: var(--margin-default) 0 var(--margin-large);
  text-decoration: underline;
}

ul {
  text-align: left;
}

.error {
  color: var(--highlight);
}
</style>