<template>
  <navigation />
  <router-view v-slot="{ Component, route }">
    <transition name="fade">
      <component :is="Component" :key="route.path" />
    </transition>
  </router-view>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import HomePage from "./components/HomePage.vue";

export default {
  name: "App",
  components: {
    Navigation,
    HomePage,
  },
};
</script>

<style>
@font-face {
  font-family: gotham;
  src: url(./assets/fonts/light.otf);
}
@font-face {
  font-family: gotham-heading;
  src: url(./assets/fonts/medium.otf);
}
@font-face {
  font-family: gotham-ultra;
  src: url(./assets/fonts/ultra.otf);
}
:root {
  --margin-tiny: 0.4rem;
  --margin-small: 0.8rem;
  --margin-default: 1.2rem;
  --margin-large: 1.6rem;
  --padding-tiny: var(--margin-tiny);
  --padding-small: var(--margin-small);
  --padding-default: var(--margin-default);
  --padding-large: var(--margin-large);
  --regular: "gotham", Helvetica, Arial, sans-serif;
  --heading: "gotham-heading", Helvetica, Arial, sans-serif;
  --bold: "gotham-ultra", Helvetica, Arial, sans-serif;
  --black: #1A1D2E;
  --white: #ffffff;
  --highlight: #BF924E;
  --transition: all 0.2s ease;
}
#app {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}
html {
  font-size: 18px;
}
@media screen and (max-width: 640px) {
  html {
    font-size: 15px;
  }
}
body {
  font-family: var(--regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  text-align: center;
  margin: 0;
  background: var(--black);
}
h1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1rem;
  margin: var(--margin-large) 0;
  color: var(--white);
}
p {
  font-size: 18px;
}
a {
  color: var(--highlight);
  text-decoration: none;
  transition: var(--transition);
  cursor: pointer;
}
strong {
  font-family: var(--bold);
  color: var(--highlight);
  text-transform: uppercase;
}
a:hover {
  opacity: 0.8;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  display: block;
  max-width: 100%;
}
.heading {
  font-family: var(--heading);
  font-size: 1.5rem;
  color: var(--highlight);
  text-transform: uppercase;
  letter-spacing: 10px;
  margin: var(--margin-default) 0;
}
.heading.sub-heading {
  font-size: 1.2rem;
  color: var(--highlight);
  margin: var(--margin-small) 0;
}
.text {
  font-size: 1.2rem;
  line-height: 1.4;
  margin: 0 0 var(--margin-default);
}
.bg-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./img/pv_press_2024_small.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.35;
  z-index: -9;
}
.container {
  position: relative;
  padding: var(--padding-default);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content {
  width: 100%;
  max-width: 700px;
  text-align: left;
  margin: 0 auto;
}
.center {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}
</style>
