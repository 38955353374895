<template>
  <div class="container">
    <div class="content flex column">
      <div class="two-col">
        <div style="border: 1px solid #333333">
          <img src="../img/something_cover.jpg" alt="I Am You - Single cover" />
        </div>
        <ul class="two-col-info">
          <li class="title">Something New (Digital)</li>
          <li>Released: March 1, 2024</li>
          <li>Label: Metropolis Records</li>
          <li class="tracks">
            <div><a href="https://www.youtube.com/watch?v=P1i8uigHrl8" target="_blank">1. Something New</a></div>
          </li>
          <li>
            <a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank" title="Buy on Bandcamp"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/artist/5LJxQhBLHFle6vcNEPewcZ?si=tTPvgK2SSUWHqG82cALZAg"
              target="_blank" title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 427.652 427.652" class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/i_am_you_cover.jpeg" alt="I Am You - Single cover" />
        </div>
        <ul class="two-col-info">
          <li class="title">I Am You (Digital)</li>
          <li>Released: August 26, 2022</li>
          <li>Label: Metropolis Records</li>
          <li class="tracks">
            <div><a href="https://youtu.be/eMmSHXU22I4" target="_blank">1. I Am You</a></div>
            <div>2. I Am You (Astari Nite Remix)</div>
            <div>3. I Am You (Solemn Youth - Adam Stilson Remix)</div>
          </li>
          <li>
            <a href="https://principevaliente.bandcamp.com/album/i-am-you" target="_blank" title="Buy on Bandcamp"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/4R1Jt8Qr30AWtkVttFgrwQ?si=-lBBN9tNSp2gyR3PTZKj8w"
              target="_blank" title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 427.652 427.652" class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/principe-valiente-barricades-vinyl-cover.jpg" alt="Barricades album" />
        </div>
        <ul class="two-col-info">
          <li class="title">Barricades (Vinyl/CD/Digital)</li>
          <li>Released: March 18, 2022</li>
          <li>Label: Metropolis Records</li>
          <li class="tracks">
            <div>1. Barricades</div>
            <div>2. So Much More</div>
            <div>
              <a href="https://youtu.be/1UM0gWWjWeY" target="_blank">3. Porcelain</a>
            </div>
            <div>
              <a href="https://www.youtube.com/watch?v=eMmSHXU22I4" target="_blank">4. I Am You</a>
            </div>
            <div>5. Tears In Different Colors</div>
            <div>6. When We Can't Let Go</div>
            <div>7. Never Change</div>
            <div>8. The Beating of Your Heart</div>
            <div>9. The Impossibles</div>
            <div>10. This Buried Love</div>
            <br />
            <div><a @click="toggleModal">Read lyrics</a></div>
          </li>
          <li>
            <a href="https://principevaliente.bandcamp.com/album/barricades" target="_blank" title="Buy on Bandcamp"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/3itWVP4qYfNYXtqxiiFj0F?si=hPBNdYjnSye-ca0LS6-qDQ"
              target="_blank" title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 427.652 427.652" class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/porcelain.jpeg" alt="Porcelain single" />
        </div>
        <ul class="two-col-info">
          <li class="title">Porcelain (Digital)</li>
          <li>Released: December 3, 2021</li>
          <li>Label: Metropolis Records</li>
          <li class="tracks">
            <div>
              <a href="https://youtu.be/1UM0gWWjWeY" target="_blank">1. Porcelain</a>
            </div>
            <div>2. Porcelain (Agent Side Grinder Remix)</div>
            <div>3. Porcelain (Ash Code Remix)</div>
            <div>4. Porcelain (TRAITRS Remix)</div>
          </li>
          <li>
            <a href="https://principevaliente.bandcamp.com/album/porcelain" target="_blank" title="Buy on Bandcamp"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/3itWVP4qYfNYXtqxiiFj0F?si=hPBNdYjnSye-ca0LS6-qDQ"
              target="_blank" title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 427.652 427.652" class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/PV_album_10.jpg" alt="Debut album 10 years" />
        </div>
        <ul class="two-col-info">
          <li class="title">Debut Album 10 Years (Digital)</li>
          <li>Released: July 23, 2021</li>
          <li>Label: Aenaos Records</li>
          <li class="tracks">
            <div>1. The Night - 10 years version</div>
            <div>2. Stay (Strings Theme) - 10 years version</div>
            <div>3. In My Arms - 10 years version</div>
            <div>4. Before You Knew Me - 10 years version</div>
            <div>5. One More Time - 10 years version</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/706158-debut-album-10-years-alternative-versions"
              target="_blank" title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/album/debut-album-10-years-alternative-versions"
              target="_blank" title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/2MB0QB7FIvS47YfjQ6P1j9" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/oceans_deluxe_cover.jpg" alt="Oceans (Deluxe)" />
        </div>
        <ul class="two-col-info">
          <li class="title">Oceans Deluxe (CD/Digital)</li>
          <li>Released: June 6, 2019</li>
          <li>Label: Aenaos Records</li>
          <li class="tracks">
            <div>1. Wildest Flowers</div>
            <div>2. When I Learned To Crawl</div>
            <div>
              <a href="https://www.youtube.com/watch?v=fo4TRnk9wCA" target="_blank">3. Strangers In The Night</a>
            </div>
            <div>4. Untouchable</div>
            <div>
              <a href="https://www.youtube.com/watch?v=HOc008ANXAg" target="_blank">5. Running Juveniles</a>
            </div>
            <div>6. No Time</div>
            <div>7. The Reason Why</div>
            <div>8. Monsters Of The Sea</div>
            <div>9. Strangers In The Night (Mister Monell Remix)</div>
            <div>10. Strangers In The Night (New Canyons Remix)</div>
            <div>11. Wildest Flowers (Big City Bright Light Remix)</div>
            <div>12. Wildest Flowers (New Canyons Remix)</div>
            <div>
              <a href="https://www.youtube.com/watch?v=nvsT_UD1mvs" target="_blank">13. When I Learned to Crawl (Live
                Piano Version)</a>
            </div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/665837-oceans-deluxe" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/album/oceans-deluxe" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/4Ls8N5bP4aWcCkAe6H0BK3" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/running_juveniles.jpg" alt="Running Juveniles" />
        </div>
        <ul class="two-col-info">
          <li class="title">Running Juveniles (Digital)</li>
          <li>Released: October 13, 2017</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <a href="https://www.youtube.com/watch?v=HOc008ANXAg" target="_blank">1. Running Juveniles</a>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/666264-running-juveniles" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/track/running-juveniles-album-version"
              target="_blank" title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/0iCApH1ZRrbXdpJJwekGej" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/oceans_cover_small.jpg" alt="Running Juveniles" />
        </div>
        <ul class="two-col-info">
          <li class="title">Oceans (Vinyl/CD/Digital)</li>
          <li>Released: May 12, 2017</li>
          <li>Label: AF Music / Manic Depression Records</li>
          <li class="tracks">
            <div>1. Wildest Flowers</div>
            <div>2. When I Learned To Crawl</div>
            <div>
              <a href="https://www.youtube.com/watch?v=fo4TRnk9wCA" target="_blank">3. Strangers In The Night</a>
            </div>
            <div>4. Untouchable</div>
            <div>
              <a href="https://www.youtube.com/watch?v=HOc008ANXAg" target="_blank">5. Running Juveniles</a>
            </div>
            <div>6. No Time</div>
            <div>7. The Reason Why</div>
            <div>8. Monsters Of The Sea</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/665927-oceans" target="_blank" title="Buy from label"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/album/oceans" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/6ZN27epWLJFMGpT9PzzRpQ" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/wildest_flowers_artwork.jpg" alt="Wildest Flowers" />
        </div>
        <ul class="two-col-info">
          <li class="title">Wildest Flowers (Digital)</li>
          <li>Released: May 5, 2021</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <div>1. Wildest Flowers</div>
            <div>2. Wildest Flowers (Big City Bright Light Remix)</div>
            <div>3. Wildest Flowers (New Canyons Remix)</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/666259-wildest-flowers" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/album/wildest-flowers" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/2Ng9U9ll723RxSl61FnrES" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/strangers_in_the_night_artwork.jpg" alt="Strangers In The Night" />
        </div>
        <ul class="two-col-info">
          <li class="title">Strangers In The Night (Digital)</li>
          <li>Released: March 10, 2021</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <div>
              <a href="https://www.youtube.com/watch?v=fo4TRnk9wCA" target="_blank">1. Strangers In The Night</a>
            </div>
            <div>2. Strangers In The Night (Mr Monell Remix)</div>
            <div>3. Strangers In The Night (New Canyons Remix)</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/666258-strangers-in-the-night" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="https://principevaliente.bandcamp.com/album/strangers-in-the-night" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/4MMoqATjF6a8SXgvJsAjEd" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/choirs_of_blessed_youth.jpg" alt="Choirs Of Blessed Youth" />
        </div>
        <ul class="two-col-info">
          <li class="title">Choirs Of Blessed Youth (CD/Digital)</li>
          <li>Released: June 6, 2014</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <div>1. The Son I'll Never Be</div>
            <div>
              <a href="https://www.youtube.com/watch?v=uFmZeQ0Dhow" target="_blank">2. Take Me With You</a>
            </div>
            <div>
              <a href="https://www.youtube.com/watch?v=By-QAkZBaBI" target="_blank">3. She Never Returned</a>
            </div>
            <div>4. The Dream</div>
            <div>5. Wasted Time</div>
            <div>6. The Fighting</div>
            <div>7. Choir Of Blessed Youth</div>
            <div>8. Fiction</div>
            <div>9. Dying To Feel Alive</div>
            <div>10. Temporary Men</div>
            <div>11. Flower In You</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/665965-choirs-of-blessed-youth" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="http://principevaliente.bandcamp.com/album/choirs-of-blessed-youth" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/57Q24IOdWK2qwWFHGS6QuX" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/she_never_returned.jpg" alt="She Never Returned" />
        </div>
        <ul class="two-col-info">
          <li class="title">She Never Returned (Digital)</li>
          <li>Released: October 25, 2013</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <a href="https://www.youtube.com/watch?v=By-QAkZBaBI" target="_blank">1. She Never Returned</a>
          </li>
          <li>
            <a href="http://principevaliente.bandcamp.com/album/she-never-returned" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/track/0hk5leSDL83nk6iq5KuIqE" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/take_me_with_you.jpg" alt="Take Me With You" />
        </div>
        <ul class="two-col-info">
          <li class="title">Take Me With You (Digital)</li>
          <li>Released: May 16, 2013</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <a href="https://www.youtube.com/watch?v=uFmZeQ0Dhow" target="_blank">1. Take Me With You</a>
          </li>
          <li>
            <a href="http://principevaliente.bandcamp.com/album/take-me-with-you" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/track/5AYyuBn7s0jPBa2aE3Ynn0" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/principe_valiente_self_titled.jpg" alt="Principe Valiente - Selt Titled Debut Album" />
        </div>
        <ul class="two-col-info">
          <li class="title">Principe Valiente (CD/Digital)</li>
          <li>Released: October 21, 2011</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <div>1. Intro</div>
            <div>2. Before You Knew Me</div>
            <div>3. One More Time</div>
            <div>
              <a href="https://www.youtube.com/watch?v=AQfBQ7De2Vg" target="_blank">4. In My Arms</a>
            </div>
            <div>5. New Life</div>
            <div>6. Solitary Man</div>
            <div>7. Stay</div>
            <div>8. Afraid</div>
            <div>9. 150 Years</div>
            <div>
              <a href="https://www.youtube.com/watch?v=g69EZnRKe0s" target="_blank">10. The Night</a>
            </div>
            <div>11. Dance Like There's No Tomorrow</div>
          </li>
          <li>
            <a href="https://www.aenaos-records.com/products/665902-principe-valiente-second-edition" target="_blank"
              title="Buy from label"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                class="icon icon-large vinyl">
                <path
                  d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
                </path>
                <circle cx="256" cy="256" r="21.333"></circle>
              </svg></a><a href="http://principevaliente.bandcamp.com/album/principe-valiente" target="_blank"
              title="Buy on Bandcamp"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em"
                style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/5DAFvbVyFg0whyLOgR7RHa" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/in_my_arms.jpg" alt="In My Arms Single" />
        </div>
        <ul class="two-col-info">
          <li class="title">In My Arms (Digital)</li>
          <li>Released: April 17, 2011</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <a href="https://www.youtube.com/watch?v=AQfBQ7De2Vg" target="_blank">1. In My Arms</a>
          </li>
          <li>
            <a href="http://principevaliente.bandcamp.com/album/in-my-arms" target="_blank" title="Buy on Bandcamp"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/1fcbUATrRbS4Il9KPOtiOb" target="_blank"
              title="Listen on Spotify"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/the_night.jpg" alt="The Night Single" />
        </div>
        <ul class="two-col-info">
          <li class="title">The Night (Digital)</li>
          <li>Released: November 16, 2010</li>
          <li>Label: AF Music</li>
          <li class="tracks">
            <a href="https://www.youtube.com/watch?v=g69EZnRKe0s" target="_blank">1. The Night</a>
          </li>
          <li>
            <a href="http://principevaliente.bandcamp.com/album/the-night" target="_blank"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop" title="Buy on Bandcamp">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/2qP31kchhzS1r9Qvkc9j5S" target="_blank"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652" class="icon icon-large pop"
                title="Listen on Spotify">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div class="two-col">
        <div>
          <img src="../img/principe_valiente-ep.jpg" alt="Principe Valiente EP" />
        </div>
        <ul class="two-col-info">
          <li class="title">Principe Valiente EP</li>
          <li>Released: May 25, 2007</li>
          <li>Label: Self-released</li>
          <li class="tracks">
            <div>1. In My Arms</div>
            <div>2. Speed of Light</div>
            <div>3. This Room</div>
            <div>4. All About You</div>
          </li>
          <li>
            <a href="https://principevaliente.bandcamp.com/album/principe-valiente-ep" target="_blank"><svg
                xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                  -ms-transform: rotate(360deg);
                  -webkit-transform: rotate(360deg);
                " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop" title="Buy on Bandcamp">
                <path
                  d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
                </path>
              </svg></a><a href="https://open.spotify.com/album/0olxsXcdwFsAcApLOP4Wpf?si=HTP6JWj8RU-wP7nRhTgR9A"
              target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 427.652 427.652"
                class="icon icon-large pop" title="Listen on Spotify">
                <path
                  d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
                </path>
              </svg></a>
          </li>
        </ul>
      </div>
      <div>
        <h1>Official Merch</h1>
        <div class="merch">
          <div>
            <img src="../img/t_shirt_newlogo_black.jpg" alt="T-Shirt Black (new logo)" />
            <div class="item-text">
              T-Shirt Black (new logo)
              <span>19€</span>
            </div>
          </div>
          <div>
            <img src="../img/t_shirt_black.jpg" alt="T-Shirt Black (cursive)" />
            <div class="item-text">
              T-Shirt Black (cursive)
              <span>19€</span>
            </div>
          </div>
          <div>
            <img src="../img/t_shirt_white.jpg" alt="T-Shirt White (cursive)" />
            <div class="item-text">
              T-Shirt White (cursive)
              <span>19€</span>
            </div>
          </div>
          <div>
            <img src="../img/tote_bag.jpg" alt="Tote Bag White" />
            <div class="item-text">
              Tote Bag
              <span>19€</span>
            </div>
          </div>
        </div>
        <div class="form">
          <p class="buy-button">
            <a href="https://principevaliente.bandcamp.com/merch" target="_blank">Buy merch
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" width="1em" height="1em" style="
                -ms-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
              " viewBox="0 0 1792 1792" transform="rotate(360)" class="icon icon-large pop">
              <path
                d="M1070 1178l306-564H722l-306 564h654zm722-282q0 182-71 348t-191 286-286 191-348 71-348-71-286-191-191-286T0 896t71-348 191-286T548 71 896 0t348 71 286 191 191 286 71 348z">
              </path>
            </svg>
          </p>
        </div>
      </div>
    </div>
    <lyrics-modal v-if="showModal" @close="toggleModal()" />
  </div>
</template>

<script>
import LyricsModal from "./LyricsModal.vue";

export default {
  name: "ShopPage",
  components: {
    LyricsModal,
  },
  data() {
    return {
      showModal: false,
      album: null,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
};
</script>

<style scoped>
.flex.column {
  flex-direction: column;
}

.two-col {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-bottom: var(--margin-large);
}

.two-col>* {
  flex: 1 1 0%;
}

@media screen and (max-width: 640px) {
  .two-col {
    display: block;
  }
}

.two-col-info {
  font-size: 0.8rem;
  margin: 0 var(--margin-tiny) 0 0;
}

@media screen and (max-width: 640px) {
  .two-col-info {
    font-size: 1rem;
    margin: var(--margin-tiny) 0 0 0;
  }
}

.two-col-info .title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: var(--margin-tiny);
}

@media screen and (max-width: 640px) {
  .two-col-info .title {
    font-size: 1.2rem;
  }
}

.two-col-info .tracks {
  font-weight: 700;
  line-height: 1.5;
  margin: var(--margin-small) 0;
}

.merch {
  display: flex;
}

.merch>div {
  flex: 1 1 0%;
  margin-bottom: var(--margin-default);
}

.merch .item-text {
  font-size: 0.8rem;
  margin-top: var(--margin-tiny);
}

.merch .item-text span {
  font-weight: 700;
}

.form {
  text-align: right;
  margin-bottom: var(--margin-large);
}

.select {
  margin-bottom: var(--margin-small);
}

.submit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 60px;
  padding: var(--padding-tiny) var(--padding-small);
  margin-top: var(--margin-small);
  border: 1px solid #263b80;
  border-radius: 30px;
  background: #cfe8f3;
  outline: none;
  cursor: pointer;
  transition: var(--transition);
}

.submit:disabled {
  border: 1px solid #ccc;
  background: #ccc;
  opacity: 0.5;
  cursor: default;
}

.submit .icon {
  width: 60px;
  height: 60px;
}

.buy-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
}

.buy-button .icon {
  margin: 0 0 0 0.3rem;
}
</style>
