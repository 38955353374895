<template>
  <div>
    <div class="container">
      <div>
        <iframe width="854" height="480" src="https://www.youtube.com/embed/P1i8uigHrl8?si=jqNAZ_Xdn9Zv3S7T"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen style="max-width: 100%;"></iframe>
          <div><a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank" title="Buy on Bandcamp">Something
                  New - Buy on Bandcamp</a></div>
      </div>
      <h1></h1>
      <p class="text">
        <strong class="album-title"><a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank"
            title="Buy on Bandcamp">Something New</a></strong><small>Single - March 1, 2024 (Digital)</small>
      </p>
      <img src="../img/something_cover.jpg" alt="'Something New' single cover" class="album-cover" />
       <!-- <div class="icons">
       <a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank" title="Pre-order on Bandcamp"
          class="promo-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon vinyl">
            <path
              d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
            </path>
            <circle cx="256" cy="256" r="21.333"></circle>
          </svg>Buy on Bandcamp</a> -->
        <!-- <a href="https://open.spotify.com/album/4aOshqSGbE0JpKi6VOGcMA?si=UBA3DmoQQp-wUfXjhJm9bg" target="_blank"
          title="Listen on Spotify" class="promo-link"><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 427.652 427.652" class="icon pop">
            <path
              d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
            </path>
          </svg>Listen on Spotify</a> 
      </div>-->

      <!-- <p class="text">
        <strong class="album-title"><a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank"
            title="Buy on Bandcamp">Barricades</a></strong><small>Album - March 18, 2022 (Vinyl/CD/Digital)</small>
      </p>
      <img src="../img/barricades.jpeg" alt="'Barricades' album cover" class="album-cover" />
      <div class="icons">
        <a href="https://principevaliente.bandcamp.com/album/something-new" target="_blank" title="Pre-order on Bandcamp"
          class="promo-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon vinyl">
            <path
              d="M256 0C114.844 0 0 114.844 0 256s114.844 256 256 256 256-114.844 256-256S397.156 0 256 0zm98.052 338.969c4.167 4.167 4.167 10.917 0 15.083-26.188 26.188-61.01 40.615-98.052 40.615A10.66 10.66 0 01245.333 384 10.66 10.66 0 01256 373.333c31.344 0 60.802-12.208 82.969-34.365 4.166-4.166 10.916-4.166 15.083.001zM121.24 129.906c-2.76 0-5.51-1.063-7.594-3.177-4.135-4.198-4.094-10.958.104-15.083C151.917 74.042 202.438 53.333 256 53.333c5.896 0 10.667 4.771 10.667 10.667S261.896 74.667 256 74.667c-47.927 0-93.125 18.531-127.271 52.167a10.628 10.628 0 01-7.489 3.072zm36.708 43.125c-4.167-4.167-4.167-10.917 0-15.083 26.188-26.188 61.01-40.615 98.052-40.615 5.896 0 10.667 4.771 10.667 10.667s-4.771 10.667-10.667 10.667c-31.344 0-60.802 12.208-82.969 34.365a10.634 10.634 0 01-7.542 3.125 10.633 10.633 0 01-7.541-3.126zM170.667 256c0-47.052 38.281-85.333 85.333-85.333s85.333 38.281 85.333 85.333-38.281 85.333-85.333 85.333-85.333-38.281-85.333-85.333zm228.635 143.302c-38.271 38.281-89.167 59.365-143.302 59.365A10.66 10.66 0 01245.333 448 10.66 10.66 0 01256 437.333c48.438 0 93.979-18.865 128.219-53.115 4.167-4.167 10.917-4.167 15.083 0 4.167 4.167 4.167 10.917 0 15.084z">
            </path>
            <circle cx="256" cy="256" r="21.333"></circle>
          </svg>Buy on Bandcamp</a>
        <a href="https://open.spotify.com/album/4aOshqSGbE0JpKi6VOGcMA?si=UBA3DmoQQp-wUfXjhJm9bg" target="_blank"
          title="Listen on Spotify" class="promo-link"><svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 427.652 427.652" class="icon pop">
            <path
              d="M213.826 0C95.733 0 0 95.733 0 213.826s95.733 213.826 213.826 213.826 213.826-95.733 213.826-213.826S331.919 0 213.826 0zm93.06 310.32c-2.719 4.652-7.612 7.246-12.638 7.247a14.56 14.56 0 01-7.364-2c-38.425-22.456-82.815-26.065-113.295-25.138-33.763 1.027-58.523 7.692-58.769 7.76-7.783 2.126-15.826-2.454-17.961-10.236-2.134-7.781 2.43-15.819 10.209-17.962 1.116-.307 27.76-7.544 64.811-8.766 21.824-.72 42.834.801 62.438 4.52 24.83 4.71 47.48 12.978 67.322 24.574 6.973 4.074 9.321 13.03 5.247 20.001zm27.184-56.459c-3.22 5.511-9.016 8.583-14.97 8.584-2.968 0-5.975-.763-8.723-2.369-45.514-26.6-98.097-30.873-134.2-29.776-39.994 1.217-69.323 9.112-69.614 9.192-9.217 2.515-18.746-2.906-21.275-12.124-2.528-9.218 2.879-18.738 12.093-21.277 1.322-.364 32.882-8.937 76.77-10.384 25.853-.852 50.739.949 73.96 5.354 29.412 5.58 56.241 15.373 79.744 29.108 8.26 4.826 11.042 15.434 6.215 23.692zm16.711-51.335c-3.641 0-7.329-.936-10.7-2.906-108.207-63.238-248.572-25.643-249.977-25.255-11.313 3.117-23.008-3.527-26.124-14.839-3.117-11.312 3.527-23.008 14.839-26.124 1.621-.447 40.333-10.962 94.166-12.737 31.713-1.044 62.237 1.164 90.72 6.567 36.077 6.844 68.987 18.856 97.815 35.704 10.13 5.92 13.543 18.931 7.623 29.061-3.95 6.76-11.059 10.529-18.362 10.529z">
            </path>
          </svg>Listen on Spotify</a>
      </div> -->
    </div>
    <div class="bg-img"></div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style>
.album-title {
  font-size: 1.8rem;
  line-height: 1;
  margin-right: var(--margin-tiny);
}

@media screen and (max-width: 640px) {
  .album-title {
    display: block;
  }
}

.album-cover {
  min-height: 280px;
  min-width: 280px;
  max-height: 60vh;
  margin-bottom: var(--margin-default);
  box-shadow: 0 6px 30px #000000;
}

.promo-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: var(--margin-small);
}

.icons {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--margin-large);
}

.icon {
  fill: var(--highlight);
  width: 20px;
  height: 20px;
  margin-right: var(--margin-tiny);
  cursor: pointer;
}
</style>
