<template>
  <div class="container">
    <div class="content">
      <section class="section">
        <h4 class="heading">Label</h4>
        <h5 class="heading sub-heading">Metropolis Records</h5>
        <a href="mailto:jim@metropolis-records.com">jim@metropolis-records.com</a>
      </section>
      <section class="section">
        <h4 class="heading">Management</h4>
        <h5 class="heading sub-heading">Hardbeat Promotion</h5>
        <a href="mailto:eric.burton@hardbeat.de">eric.burton@hardbeat.de</a>
      </section>
      <section class="section">
        <h4 class="heading">Publishing</h4>
        <h5 class="heading sub-heading">Schubert Music Publishing</h5>
        <a href="mailto:k.schlegel@schubertmusic.com">e.burton@schubertmusic.com</a>
      </section>
      <section class="section">
        <h4 class="heading">Booking</h4>
        <h5 class="heading sub-heading">Protain</h5>
        <a href="mailto:diane@protain.de">diane@protain.de</a>
      </section>
      <section class="section">
        <h4 class="heading">Contact</h4>
        <h5 class="heading sub-heading">Band</h5>
        <a href="mailto:info@principevaliente.com">info@principevaliente.com</a>
      </section>
      <section class="section">
        <h4 class="heading">Press Photo</h4>
        <img src="../img/principe_valiente_2024.jpg" width="1220" alt="Promo Picture 2024" />
      </section>
      <section class="section">
        <h4 class="heading">Press Kit</h4>
        <router-link to="/press" class="download">Log in</router-link>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
};
</script>

<style scoped>
.content {
  text-align: center;
}

.section {
  padding-bottom: var(--padding-large);
  margin-bottom: var(--margin-large);
}

.heading {
  font-size: 1.4rem;
}

.sub-heading {
  font-size: 1.1rem;
}

.download {
  display: block;
  text-decoration: underline;
  margin: var(--margin-small) 0;
}
</style>