<template>
  <div class="container">
    <div class="content">
      <p>"In This Light" is the title of Principe Valiente's latest full-length album, marking a bold evolution in the band's decade-plus journey. The ten meticulously crafted songs unveil the signature ethereal soundscape and dark ambient allure that initially propelled them onto the alternative scene in 2011 with the release of their self-titled debut album.</p>
      <p>Immerse yourself in a symphony of mellotrons, samplers, and analog synths harmonizing with the celestial resonance of Jimmy Ottosson's Rickenbacker 12-string and Gretsch guitars. This musical landscape is further enriched by the melodic piano, the distinctively distorted driving bass, all enveloped in the enigmatic vocals of singer Fernando Honorato.</p>
      <p>The two upcoming singles, "Something New" and "Inspired By Me," signal the new direction of the current two-man band, adopting a more pop-oriented and direct approach than before.</p>
      <p>Anticipate the release of the new album May 3 2024 by Metropolis Records.</p>
      <p>/Stockholm, February 2024</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BioText",
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 700px;
  text-align: left;
  margin: 0 auto;
}
</style>
