<template>
  <div class="modal">
    <div class="modal-header">
      <span>Barricades - album lyrics</span>
      <a @click="$emit('close')">Close</a>
    </div>
    <div class="modal-content">
      <div id="barricades">
        <h3>Barricades</h3>
        <p>
          I know that I can make you understand<br />
          that all my choices are not made out of fear
        </p>
        <p>
          You know you have to change your taste again<br />
          no matter what they say about me
        </p>
        <p>
          I hope that I can give this<br />
          one more chance<br />
          but will it give<br />
          me a reason to live
        </p>
        <p>
          (I can feel this urge to protect you<br />
          one more time<br />
          through the lands<br />
          and the future<br />
          until nothing feels the same<br />
          again..)
        </p>
        <p>
          I know I have to make you understand<br />
          but will this take us closer to the truth
        </p>
        <p>
          I know that I've changed your taste in men<br />
          and for a moment I thought<br />
          this was only all about youth
        </p>
        <p>
          Singing along to these tunes<br />
          for decades<br />
          cause I still believe
        </p>
        <p>
          But I keep building my own<br />
          barricades<br />
          (la-la-la)
        </p>
        <p>
          When all I want is for you<br />
          to stay
        </p>
        <p>
          For you to stay<br />
          (I keep building my own barricades)
        </p>
        <p>For you to stay..</p>
        <h3>So much more</h3>

        <p>
          I didn't have to be scared at all<br />
          cause at the end I was the one to fall<br />
          when it was you who came to me<br />
          on and on<br />
          but sometimes<br />
          we shared one soul
        </p>

        <p>
          Now everybody wants to see<br />
          a piece of this<br />
          but I feel like<br />
          a fool
        </p>

        <p>
          When everyone wants to put<br />
          me on hold<br />
          but I long<br />
          for so much more
        </p>

        <p>
          I am glad I was careful<br />
          in the end<br />
          cause otherwise it could have been<br />
          like a real hell again..
        </p>

        <p>
          Everybody wants a piece of this tonight<br />
          but my heart is not made<br />
          of stone
        </p>

        <p>
          When everyone wants to put me<br />
          on hold<br />
          like in the prom<br />
          but you wore the crown
        </p>

        <p>
          Now everybody wants to see<br />
          a piece of this<br />
          but I long for more
        </p>

        <p>
          Everyone sees me like I was cold<br />
          when all I know<br />
          is to be alone<br />
          Sometimes I feel<br />
          come back to me<br />
          with so much more
        </p>

        <p>
          Like we were seventeen<br />
          we could take our pills<br />
          and just go<br />
          go with the flow
        </p>

        <p>
          The seven seas<br />
          I could've crossed<br />
          for the two of us
        </p>

        <p>
          But then<br />
          I change<br />
          to this whore
        </p>

        <p>
          I have to ignore<br />
          and close that door<br />
          from this life
        </p>

        <p>
          from my life..<br />
          from my life..<br />
          from my life..
        </p>

        <h3>Porcelain</h3>

        <p>
          I wonder how<br />
          this whole story is not an illusion<br />
          where we say goodbye
        </p>

        <p>
          The scene is now<br />
          we look straight out into the ocean<br />
          with no need to cry
        </p>

        <p>
          And when time strikes again<br />
          they'll be waiting<br />
          for the next crime
        </p>

        <p>
          And it's hard to run<br />
          into the open wild and the ocean<br />
          without saying goodbye
        </p>

        <p>
          Here and now..<br />
          here and now..<br />
          here and now..
        </p>

        <p>
          And when time strikes again<br />
          they'll be waiting<br />
          for the next fight
        </p>

        <p>I'll be free from the lion's den</p>

        <p>
          For the awakening<br />
          after a thousand nights
        </p>

        <h3>I am You</h3>

        <p>
          I see what you like<br />
          when you look away<br />
          will I spend my nights with you<br />
          until I die
        </p>

        <p>
          I can't deny<br />
          that I used to wait<br />
          then I fell in love with you but I couldn't<br />
          hide
        </p>

        <p>
          Will I get from here alive?<br />
          as we dream by the fire<br />
          and we will soon be afraid<br />
          of the shades we create
        </p>

        <p>
          So hold on<br />
          to your dreams
        </p>

        <p>
          It takes a lot of bones<br />
          to live a truthful life
        </p>

        <p>
          But we don't leave any trace<br />
          cause I am you..
        </p>

        <p>So hold on to me</p>

        <p>
          I'm tired of believing in<br />
          my own lies<br />
          but we don't leave any trace
        </p>

        <p>So hold on // (in me)</p>

        <p>
          Just hold on to me<br />
          to me..
        </p>

        <p>Cause I am you</p>

        <p>
          Will I get from here alive?<br />
          as we dream by the fire
        </p>

        <p>
          Will I get from here alive?<br />
          (alive)
        </p>

        <p>
          (Will I get from)<br />
          Will I get from here<br />
          will I get from here alive?
        </p>

        <p>
          (Will I get from here)<br />
          as we dream by the fire..
        </p>

        <p>
          Will I get from / Get away<br />
          will I get from here alive?
        </p>

        <p>(Will I get from)</p>

        <h3>Tears in Different Colors</h3>

        <p>
          They say after all<br />
          I've changed your mind<br />
          I'm lying straight on the floor<br />
          but I don't know why
        </p>

        <p>
          Too much time<br />
          wasted on stand-by<br />
          well, it was the real thing<br />
          for a while
        </p>

        <p>
          They say that after all<br />
          we were sharp as a knife<br />
          but I guess it's the usual way<br />
          in this high life
        </p>

        <p>
          We couldn't even walk in a straight line<br />
          we both know it was the real thing<br />
          for a while
        </p>

        <p>
          para-rah para-rah para-rah pah-pah<br />
          para-rah para-rah para-rah pah-pah<br />
          para-rah para-rah para-rah pah-pah<br />
          para-rah pah-pah
        </p>

        <p>
          In my early years<br />
          I was easily adapted
        </p>

        <p>
          we managed our fears<br />
          but felt so divided
        </p>

        <p>
          We had to realize<br />
          we were not in wonderland<br />
          (I've always been trusted)<br />
          I've always been trusted
        </p>

        <p>
          para-rah para-rah para-rah pah-pah<br />
          para-rah para-rah para-rah pah-pah<br />
          para-rah para-rah para-rah pah-pah<br />
          para-rah pah-pah
        </p>

        <p>
          We saw our own tears<br />
          in different colors<br />
          we were pioneers<br />
          in our second hand furs
        </p>

        <p>
          But we always knew how to walk<br />
          down through the hall<br />
          (I'll never get married)
        </p>

        <p>
          I'll never get married<br />
          even after so much I've left behind<br />
          and all the things<br />
          we'll see in wonderland
        </p>

        <p>
          (You'll always be carried)<br />
          You'll always be carried<br />
          cause I'll never learn
        </p>

        <h3>When We Can't Let Go</h3>

        <p>
          All this talk about love<br />
          makes no sense<br />
          when there's silence from above
        </p>

        <p>
          Change your name<br />
          as I've done<br />
          and far from here<br />
          we'll steal their horses and run
        </p>

        <p>Along the violence of the waves..</p>

        <p>
          And when the sun hits the floor<br />
          I hope to get back my soul
        </p>

        <p>
          But the fun has just began<br />
          we adore what we can't control
        </p>

        <p>
          Sleep your ways when I'm gone<br />
          and you could be my own sparkling star
        </p>

        <p>Along the violence of the waves..</p>

        <p>
          When the sun hits again<br />
          I hope you can return my soul
        </p>

        <p>
          But the fun has just began<br />
          we adore what we can't control
        </p>

        <p>
          Let the sun<br />
          change your mind
        </p>

        <p>
          Let the rain clean you<br />
          through all eternity
        </p>

        <p>
          But I have<br />
          to keep you all outside
        </p>

        <p>
          And your hands<br />
          away from me
        </p>

        <p>
          The sun is on our side<br />
          well is not to late<br />
          to change your mind
        </p>

        <p>
          We both know<br />
          we just can't let go
        </p>

        <p>
          What we can't control<br />
          cause we adore<br />
          when we can't let go
        </p>

        <h3>Never Change</h3>

        <p>
          Even if we sometimes can be<br />
          like glue<br />
          my body expects you'll break my heart<br />
          in two
        </p>

        <p>
          I still have thoughts of holding back<br />
          it's true<br />
          but I'll do everything I can<br />
          for you
        </p>

        <p>
          I know we were just lovers<br />
          but we can make history<br />
          I can't believe again<br />
          I am a part of this
        </p>

        <p>
          I wanted you to discover<br />
          what I have inside of me these days<br />
          and that I can be a part<br />
          of the human race
        </p>

        <p>
          You told me we were lovers<br />
          and I pretended I never cared<br />
          I can't believe I've been<br />
          thrown back again
        </p>

        <p>
          Even the nights when I'm quite sure<br />
          we are through<br />
          I will not paint back my room<br />
          in blue
        </p>

        <p>
          I've got just suddenly new hope<br />
          it's true<br />
          cause I'll do everything I can<br />
          for you
        </p>

        <p>
          For you<br />
          for you..
        </p>

        <p>
          I know we were just lovers<br />
          but we can solve this mistery<br />
          again I have to dare<br />
          and be a part of this..
        </p>

        <p>(For you..)</p>

        <p>
          I saw you on the cover<br />
          when I thought you would never change<br />
          again I have to turn it<br />
          to some kind of bliss
        </p>

        <p>
          I wanted you to discover<br />
          what I have inside of me these days<br />
          I can't believe I've been<br />
          thrown back again
        </p>

        <p>(For you..)</p>

        <p>
          (I can't believe I've been<br />
          thrown back again
        </p>

        <p>For you..)</p>

        <p>
          (I can't believe I've been<br />
          thrown back again
        </p>

        <p>For you..)</p>

        <p>
          I wanted you to discover<br />
          what I have inside of me these days
        </p>

        <p>
          And that I<br />
          can be a part<br />
          of the human race
        </p>

        <h3>The Beating of Your Heart</h3>

        <p>
          I sing along<br />
          even if you don't understand<br />
          lying next to you<br />
          with the beating of your heart
        </p>

        <p>
          When laying here<br />
          was nothing I ever planned<br />
          so I sing along<br />
          but you'll never understand
        </p>

        <p>
          I sing along<br />
          while I have<br />
          a clear sight<br />
          waiting<br />
          for the night
        </p>

        <p>
          I'm lying here<br />
          with the beating of your heart
        </p>

        <p>
          So I suppose it's really<br />
          a good start
        </p>

        <p>
          So ice cold<br />
          was the touch<br />
          of the skin<br />
          of the sins and we had<br />
          to leave all alone
        </p>

        <p>
          This night<br />
          you held my hand<br />
          so tight
        </p>

        <p>
          But this time<br />
          I'll keep<br />
          my head up high<br />
          and take this love<br />
          into the light
        </p>

        <p>
          Into the light<br />
          to the light..<br />
          to the light..<br />
          to the light..<br />
          to the light..
        </p>

        <h3>The Impossibles</h3>

        <p>
          Hanging on the telephone<br />
          like in the 90's<br />
          we all know there's so much more<br />
          than those tears
        </p>

        <p>
          Fooling around with celebrities<br />
          all over me<br />
          was nothing more than a cold breeze
        </p>

        <p>
          Let's go<br />
          change my plan<br />
          so c'mon<br />
          and take my hand
        </p>

        <p>
          We are impossible<br />
          you know it's natural<br />
          it's not irrational<br />
          you know it's casual
        </p>

        <p>
          You know it's wonderful<br />
          and irresponsible<br />
          you are an animal<br />
          we are the impossibles
        </p>

        <p>
          Hanging out with politicians<br />
          in my own place<br />
          was like something from outer space
        </p>

        <p>
          Fucking around with television personalities<br />
          was just a way to get over this
        </p>

        <p>
          Let's go<br />
          change my plan<br />
          so c'mon<br />
          and grab my hand
        </p>

        <p>
          We are the impossibles<br />
          you know it's natural<br />
          not philosophical<br />
          you know it's casual
        </p>

        <p>
          You know it's wonderful<br />
          and irresponsible<br />
          you are an animal<br />
          we are impossible
        </p>

        <p>
          You know it's casual<br />
          it's not irrational<br />
          you are an animal<br />
          it's international
        </p>

        <p>
          You know it's wonderful<br />
          and unstoppable<br />
          it's just biological<br />
          we are the impossibles
        </p>

        <h3>This Buried Love</h3>

        <p>
          Sometimes<br />
          we keep going around the clock<br />
          they'll never come<br />
          we've all heard that one before
        </p>

        <p>
          Forever young<br />
          in me
        </p>

        <p>
          We met so long ago<br />
          and we let our hearts go on<br />
          all I see are empty streets<br />
          but we have to let it be
        </p>

        <p>
          Sometimes<br />
          we can read each other's thoughts
        </p>

        <p>
          Forever young<br />
          while this dance says is all
        </p>

        <p>
          I need that piece of mind<br />
          when we leave them all behind<br />
          all I see are empty streets<br />
          now we have to let it be
        </p>

        <p>Now we have to let it be..</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal {
  position: fixed;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  width: 80%;
  height: 80%;
  max-width: 600px;
  max-height: 600px;
  margin: 0 auto;
  color: white;
  background: black;
  overflow: hidden;
}

.modal-content {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
}

.modal-header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  text-align: left;
  padding: 1rem;
  background: black;
  z-index: 1;
}

p {
  font-size: 14px;
}

h3 {
  margin-top: 4rem;
}</style>